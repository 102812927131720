module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"C:\\Users\\User\\dev\\sites\\astralanalitics\\src\\containers\\layout\\layout.js"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1200},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Mitech","short_name":"mitech","theme_color":"#086ad8","background_color":"#ffffff","display":"standalone","scope":"/","start_url":"/","icon":"src/assets/images/astralanalitics-favicon.png","icons":[{"src":"/icons/astralanalitics-favicon-006.ico","sizes":"72x72","type":"image/png"},{"src":"/icons/astralanalitics-favicon-008.ico","sizes":"96x96","type":"image/png"},{"src":"/icons/astralanalitics-favicon-001128.ico","sizes":"128x128","type":"image/png"},{"src":"/icons/astralanalitics-favicon-003144.ico","sizes":"144x144","type":"image/png"},{"src":"/icons/astralanalitics-favicon-001152.ico","sizes":"152x152","type":"image/png"},{"src":"/icons/astralanalitics-favicon-0012.ico","sizes":"192x192","type":"image/png"},{"src":"/icons/astralanalitics-favicon-002256.ico","sizes":"256x256","type":"image/png"},{"src":"src/assets/images/astralanalitics-favicon.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"03b7b975b1464790558ab780f3e648f2"},
    },{
      plugin: require('../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true,"autoGenHomeLabel":"Home","exclude":["/dev-404-page","/404","/404.html"],"useClassNames":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
